<template>
  <div>
    <p class="mb-3">Consider the reaction shown below:</p>
    <p class="mb-3 pl-4">
      <chemical-latex content="HA(aq) + H2O(l) <=> A^-(aq) + H3O^+(aq)" />
      <stemble-latex content="$\qquad\qquad \text{K}_{\text{f}}=$" />
      <number-value :value="Ka" unit="\text{M}" />
    </p>
    <p class="mb-0">
      What is the value of the equilibrium constant for the reverse reaction,
      <stemble-latex content="$\text{K}_{\text{r}}?$" />
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6 mb-n6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <latex-number :number="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import LatexNumber from '../displayers/LatexNumber';
import {toLatexScientificNotation} from '../../utils/math';

export default {
  name: 'Question170e',
  components: {
    StembleLatex,
    ChemicalLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    Ka() {
      return this.taskState.getValueBySymbol('Ka').content;
    },
    KaFloat() {
      return this.Ka ? this.Ka.toFloat() : null;
    },
    KaOppositeSign() {
      return 1e10 * this.KaFloat;
    },
    reciprocal() {
      return 1 / this.KaFloat;
    },
    negative() {
      return -1 * this.KaFloat;
    },
    Kb() {
      return 1e-14 / this.KaFloat;
    },
    options1() {
      return [
        {
          expression:
            '\\text{K}_{\\text{r}}=+' + toLatexScientificNotation(this.KaFloat.toPrecision(3)),
          value: 'Ka',
        },
        {
          expression:
            '\\text{K}_{\\text{r}}=' + toLatexScientificNotation(this.negative.toPrecision(3)),
          value: 'negative',
        },
        {
          expression:
            '\\text{K}_{\\text{r}}=+' +
            toLatexScientificNotation(this.KaOppositeSign.toPrecision(3)),
          value: 'oppositeExponent',
        },
        {
          expression: '\\text{K}_{\\text{r}}=+' + toLatexScientificNotation(this.Kb.toPrecision(3)),
          value: 'Kb',
        },
        {
          expression:
            '\\text{K}_{\\text{r}}=+' + toLatexScientificNotation(this.reciprocal.toPrecision(3)),
          value: 'reciprocal',
        },
      ];
    },
  },
};
</script>
